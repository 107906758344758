import axios from 'axios'
import { ACCESS_TOKEN, REFRESH_TOKEN, API_BASE_URL, LOGIN_DATA } from './apiConstants'

// Base url instance
const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 1000000
})

// function to refresh token
const refreshTokens = async (token, userId) => {
  // console.log("token------------",token)
  let newRes
  try {
    newRes = await instance.post('/login/refresh_token', { refresh_token: token, user_id: userId }) // token.refreshToken
    localStorage.setItem(ACCESS_TOKEN, newRes.data.token.access_token)
    localStorage.setItem(REFRESH_TOKEN, newRes.data.token.refresh_token)
  } catch (error) {
    newRes = null
  }

  return newRes
}

// function to remove localstorage data
const sessionExpired = () => {
  localStorage.clear()
  return (window.location.href = '/')
}

// function to define get request
export const getAuth = async (url, params) => {
  const axiosConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
  const token = localStorage.getItem(ACCESS_TOKEN) ? localStorage.getItem(ACCESS_TOKEN) : {}
  const refreshToken = localStorage.getItem(REFRESH_TOKEN) ? localStorage.getItem(REFRESH_TOKEN) : {}
  const userId = localStorage.getItem(LOGIN_DATA) ? JSON.parse(localStorage.getItem(LOGIN_DATA)).data.user_id : {}
  axiosConfig.headers.Authorization = token
  const options = {
    ...axiosConfig,
    ...params
  }

  // console.log'options=========>',options);
  const res = await instance.get(url, options)
  // console.log(res.data.msg);
  let respo
  if (res.data.msg === 'Invalid Token' || res.data.msg === 'Signature verification failed') {
    return sessionExpired()
  }
  if (res.data.msg === 'Token Expired') {
    const newRes = await refreshTokens(refreshToken, userId)

    // console.log('newRes--------------->',newRes.data.token.access_token);

    // console.log(newRes);

    if (newRes) {
      axiosConfig.headers.Authorization = newRes.data.token.access_token
      const options = {
        ...axiosConfig,
        ...params
      }

      const response = await instance.get(url, options)

      // console.log('response-------->',response);

      if (response.data.msg === 'Invalid Token') {
        return sessionExpired()
      }
      respo = response
    } else {
      respo = null
      return sessionExpired()
    }
  } else {
    respo = res
  }
  // console.log('respo---------------->',respo);
  return respo
}

// function to handle authorization post request
export const authPost = async (url, data) => {
  const axiosConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
  const token = localStorage.getItem(ACCESS_TOKEN) ? localStorage.getItem(ACCESS_TOKEN) : {}
  const refreshToken = localStorage.getItem(REFRESH_TOKEN) ? localStorage.getItem(REFRESH_TOKEN) : {}
  const userId = localStorage.getItem(LOGIN_DATA) ? JSON.parse(localStorage.getItem(LOGIN_DATA)).data.user_id : {}
 // axiosConfig.headers.Authorization = token
  const options = {
    ...axiosConfig
  }

  let res
  res = await instance.post(url, data, options)
  console.log(res.data.msg)
  if (res.data.msg === 'Invalid Token' || res.data.msg === 'Signature verification failed') {
    return sessionExpired()
  }
  if (res.data.msg === 'Token Expired') {
    const newRes = await refreshTokens(refreshToken, userId)

    axiosConfig.headers.Authorization = newRes.data.token.access_token

    res = await instance.post(url, data, axiosConfig)
    if (res.data.msg === 'Invalid Token' || res.data.msg === 'Signature verification failed') {
      return sessionExpired()
    }
  }
  return res
}

// function to handle post request
export const post = async (url, data) => {
  const axiosConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
  const res = await instance.post(url, data, axiosConfig)
  return res
}

// function to handle login post request
export const login = async (url, data) => {
  const axiosConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
  const res = await instance.post(url, data, axiosConfig)
  return res
}

export const getReq = async (url, data) => {
  const axiosConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
  const res = await instance.get(url, data, axiosConfig)
  return res
}
