import React,{useEffect} from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './core/theme'
import { Provider } from 'react-redux'
import store from '../app/store/index'
import './App.css'
import './common.css'
import Main from './Main'
import Footer from './pages/footer/Footer'
import Header from './pages/header/Header';

function App () {
  // useEffect(() => {
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   //  alert("Right click disabled")
  //   });
  // },[]);
  return (
    <ThemeProvider theme={theme}>
    <Provider store={store}>
        <Router  forceRefresh={false}>
        {/* basename="/build" */}
          {/* <Test /> */}
          <Header />
            <Main />
        </Router>

        <Footer />
    </Provider>
    </ThemeProvider>
  )
}

export default App
