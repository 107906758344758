import React, { useCallback, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import { Col, Row } from 'react-bootstrap'
import '@szhsin/react-menu/dist/index.css';
import logo from "../../assets/images/itwlogo.png";
import { Button, TextField, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, PROFILE_IMAGE } from '../../utility/apiConstants';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './header.css'
import { countrylist } from '../../services/countryList';
import { emailValidator, fnameVali, lnameVali } from '../user/validation';
import jwt_decode from "jwt-decode";
import OutlinedInput from '@mui/material/OutlinedInput';

const SignUp = () => {
    const dispatch = useDispatch();
    const { isLoading, userDetails } = useSelector(state => state.user)
    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        billing_email: null,
    });
    const [registers, setRegisters] = useState({
        'title': null, 'first_name': null,
        'last_name': null, 'country_code': null,
        'phone': null, 'email': null,
        'password': null, 'reTypePassword': null
    })
    const [error, setError] = useState({
        'title': null, 'first_name': null,
        'last_name': null, 'country_code': null,
        'phone': null, 'email': null,
        'password': null, 'reTypePassword': null
    })
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleRegister = event => {
        error[event.target.name] = null
        setRegisters({ ...registers, [event.target.name]: event.target.value })
    };
    function handleCallbackresponse(response) {
        console.log("Google Response", response.credential)
        let credential = response.credential;
        var decoded = jwt_decode(credential);
        console.log("Decoded output", decoded)
    }

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        if (window.google) {
            window.google.accounts.id.initialize({
                client_id: "18242223289-mndpofq0263cb289suesrrlo82j2ir8u.apps.googleusercontent.com",
                callback: handleCallbackresponse
            });
            window.google.accounts.id.renderButton(
                document.getElementById("logingoogle"),
                { theme: "outline", size: "large" }
            );
        }
    }, []);

    const onCreateCompany = async (item) => {
        let dataToSend = {
            "company_name": item.companyName,
            "address": item.address,
            "countryCode": item.countryCode,
            "gstNum": item.gstNum,
            "phone": item.phone,
            "email": item.email,
            "pan": item.pan
        }
        console.log("dataToSend", dataToSend);
    }

    const checkValidation = () => {
        var number = registers.phone;
        var country = registers.country_code;
        var phoneNumber = country + " " + number;

        let firstNameErr = fnameVali(registers.first_name);
        let lastNameErr = lnameVali(registers.last_name);
        let countryCode = registers.country_code === null ? "Please enter country code" : null;
        // let gst = gstJstNumVali(companyDetails.gstNum);
        let phone = registers.phone === null ? "Please enter phone number" : phoneNumber.length <= 6 ? "Too short" : isValidNumber(phoneNumber) ? null : "Please enter a valid phone number.";
        let email = emailValidator(registers.email);
        let pwdErr = registers.password === null ? "Please enter password" : registers.password.length <= 6 ? "Password is too short" : null;
        let reTypePwdErr = registers.reTypePassword === null ? "Please retype password" :
            registers.password !== registers.reTypePassword ? "password & retype password must be same" : null
        if (countryCode || phone || email || firstNameErr || lastNameErr || pwdErr || reTypePwdErr) {
            setError({
                ...error,
                first_name: firstNameErr,
                last_name: lastNameErr,
                password: pwdErr,
                reTypePassword: reTypePwdErr,
                country_code: countryCode,
                phone: phone,
                email: email,
            })
            return;
        }
        else {
            console.log("Register", registers)
        }
    }

    function isValidNumber(number) {
        return new window.libphonenumber.parsePhoneNumber(number).isValid()
    }

    const handlePhoneChange = (e) => {
        setError({ ...error, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setRegisters({ ...registers, phone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setRegisters({ ...registers, phone: number })
        }
    }
    
    return (
        <div className='inputsect_signup'>
            <img src={userDetails && userDetails.logo ? PROFILE_IMAGE + userDetails?.logo : logo} className="loginlogo" alt="logo" />
            <div className='textBoxsign'>
                <Row>
                    <Col xs={12} md={2}>
                        <label>Title</label>

                        <FormControl variant="outlined" className='emailfield' margin="dense" fullWidth>
                            {/* <InputLabel id="demo-simple-select-outlined-label">Title</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={registers.title}
                                name='title'
                                onChange={(e) => {
                                    handleRegister(e)
                                }}
                            >
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={5}>
                        <label>First Name</label>
                        <TextField
                            className='emailfield'
                            variant='outlined'
                            autoFocus
                            margin="dense"
                            id="name"
                            placeholder="First Name"
                            type="text"
                            fullWidth
                            name="first_name"
                            value={registers.first_name}
                            onChange={(e) => {
                                handleRegister(e)
                            }}
                            error={!!error.first_name}
                            helperText={error.first_name}
                        />
                    </Col>
                    <Col xs={12} md={5}>
                        <label>Last Name</label>
                        <TextField
                            className='emailfield'
                            variant='outlined'
                            autoFocus
                            margin="dense"
                            id="name"
                            placeholder="Last Name"
                            type="text"
                            fullWidth
                            name="last_name"
                            value={registers.last_name}
                            onChange={(e) => {
                                handleRegister(e)
                            }}
                            error={!!error.last_name}
                            helperText={error.last_name}
                        />
                    </Col>

                    <Col xs={12} md={12}>
                        <label className='mt-3'>Email Address</label>
                        <TextField
                            className='emailfield'
                            variant='outlined'
                            autoFocus
                            margin="dense"
                            id="name"
                            placeholder="Email Address"
                            type="email"
                            fullWidth
                            name="email"
                            value={registers.email}
                            onChange={(e) => {
                                handleRegister(e)
                            }}
                            error={!!error.email}
                            helperText={error.email}
                        />
                    </Col>

                    <Col xs={4} md={3}>
                        <label className='mt-3'>Code</label>
                        <FormControl variant="outlined" className='emailfield' margin="dense" fullWidth>
                            {/* <InputLabel id="demo-simple-select-outlined-label">+91</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="selectCountry"
                                variant="outlined"
                                name="country_code"
                                placeholder='Country Code'
                                value={registers.country_code}
                                error={error.country_code}
                                helperText={error.country_code}
                                onChange={handleRegister}
                            >
                                {countrylist.map(option => (
                                    <MenuItem key={option.origin} value={option.country_code}>
                                        {option.name + " (" + option.country_code + ")"}
                                    </MenuItem>
                                ))}
                            </Select>
                            {error.country_code !== null && (<FormHelperText style={{ color: "red" }}>{error.country_code}</FormHelperText>)}
                        </FormControl>
                    </Col>
                    <Col xs={8} md={9}>
                        <label className='mt-3'>Mobile Number</label>
                        <TextField
                            className='emailfield'
                            variant='outlined'
                            autoFocus
                            margin="dense"
                            placeholder="Mobile Number"
                            fullWidth
                            name="phone"
                            value={registers.phone}
                            error={error.phone}
                            helperText={error.phone}
                            onChange={handlePhoneChange}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <label className='mt-3'>Password</label>
                        <FormControl sx={{  width: '100%' }} variant="outlined">
                            <OutlinedInput
                            className='emailfield mt-2'
                            id="outlined-adornment-password"
                            type={showPassword1 ? 'text' : 'password'}
                            name="password"
                            value={registers.password}
                            error={error.password}
                            helperText={error.password}
                            onChange={handleRegister}
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword1}
                                    onMouseDown={handleMouseDownPassword1}
                                    edge="end"
                                >
                                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            }
                            />
                            <FormHelperText id="component-helper-text" className='text-danger'>
                            {error.password}
                            </FormHelperText>
                        </FormControl>
                        {/* <TextField
                            className='emailfield'
                            variant='outlined'
                            autoFocus
                            margin="dense"
                            id="name"
                            placeholder="Password"
                            type="password"
                            fullWidth
                            name="password"
                            value={registers.password}
                            error={error.password}
                            helperText={error.password}
                            onChange={handleRegister}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large">
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        /> */}
                    </Col>

                    <Col xs={12} md={6}>
                        <label className='mt-3'>Re-Type Password</label>
                        <FormControl sx={{  width: '100%' }} variant="outlined">
                            <OutlinedInput
                            className='emailfield mt-2'
                            id="outlined-adornment-password"
                            type={showPassword2 ? 'text' : 'password'}
                            name="reTypePassword"
                            value={registers.reTypePassword}
                            error={error.reTypePassword}
                            helperText={error.reTypePassword}
                            onChange={handleRegister}
                            endAdornment={
                                <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword2}
                                    onMouseDown={handleMouseDownPassword2}
                                    edge="end"
                                >
                                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            />
                            <FormHelperText id="component-helper-text" className='text-danger'>
                            {error.reTypePassword}
                            </FormHelperText>
                        </FormControl>
                        {/* <TextField
                            className='emailfield'
                            variant='outlined'
                            autoFocus
                            margin="dense"
                            id="name"
                            placeholder="Re-Type Password"
                            type="password"
                            fullWidth
                            name="reTypePassword"
                            value={registers.reTypePassword}
                            error={error.reTypePassword}
                            helperText={error.reTypePassword}
                            onChange={handleRegister}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large">
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        /> */}
                    </Col>
                </Row>
            </div>

            <div className='btnpostion_signup'>
                <Button onClick={() => checkValidation()} className='Btnlogin  mt-4' variant='contained' color='primary'>Sign Up</Button>
                {/* <div className='loginfrg'>
            <span>Alredy I have an account </span>
        </div> */}
            </div>

            <div className='ttext'>Or</div>
            <div className='btnpostion_signup'>
                {/* <Button className='Btnlogin' startIcon={<DeleteIcon />} variant='contained' color='primary'>Login</Button> */}
                <div className='logingoogle' id="logingoogle">
                        {/* <img src={googleico} className="img_with" /><span>Login with Google</span> */}
                    </div>
            </div>
            <div className="texttitle">
                By proceeding, you agree to ITWgo
                <NavLink target="_blank" href="privacy-policy" to="privacy-policy">
                    <span style={{ color: 'blue' }}> Privacy Policy, User Agreement and T&Cs</span>
                </NavLink>
            </div>
        </div>
    );
}

export default SignUp