import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap'
import '@szhsin/react-menu/dist/index.css';
import logo from "../../assets/images/itwlogo.png";
import uxplogo from "../../assets/images/itwuxp.png";
import { Badge, Box, Button, SvgIcon } from '@mui/material';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUserAccountDetails } from '../../store/user/actions/userAcountActions';
import { API_BASE_URL, PROFILE_IMAGE } from '../../utility/apiConstants';
import Dialog from '@mui/material/Dialog';
import Slider from "react-slick";
import img1 from '../../assets/images/sports/golf.png'
import img2 from '../../assets/images/sports/tennis.png'
import img3 from '../../assets/images/sports/f1.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import './header.css'
import SignIn from './SignIn';
import SignUp from './SignUp';
import Modal from 'react-bootstrap/Modal';

const Header = () => {
  const dispatch = useDispatch();
  const { isLoading, userDetails } = useSelector(state => state.user)
  const [open, setOpen] = useState(false);
  const [opensignmodal, setOpensignmodal] = useState(false);
  const [openmodal, setOpenmodal] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const [openNav, setOpenNav] = React.useState(false);

  const handleClickNav = () => {
    setOpenNav((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenNav(false);
  };
  const handleClickOpen = () => {
    setOpenmodal(true);
  };
  const handleClickClose = () => {
    setOpenmodal(false);
  };

  const handleSignOpen = (e) => {
    e.preventDefault()
    setOpensignmodal(true);
  };

  const handleSignClose = () => {
    setOpensignmodal(false);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  useEffect(() => {
    dispatch(getUserAccountDetails())
  }, [dispatch]);

  //sliderlogin 
  var lginslider = {
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        lginslider: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        lginslider: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        lginslider: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  ////

  return (
    <div className="header sticky-parent sticky-child" id="header">
      {/* {userDetails ? ( */}

      <Container className=''>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="headercol ">
            <Navbar collapseOnSelect expand="lg" variant="light" className="NavbarHeader ">
              <Navbar.Brand href="/" className='logoContainer'>
                <NavLink to="/">
                  <img src={userDetails && userDetails.logo ? PROFILE_IMAGE + userDetails?.logo : logo} className="itw-logo" alt="logo" />
                </NavLink>
                <Box className='logo-side'>
                  <p>Powered By</p>
                  <img src={uxplogo} />
                </Box>
              </Navbar.Brand>

              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
               */}
               <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleClickAway}
                  >
                    <Box className='box-header-clickaway'>
                      <Button aria-controls="responsive-navbar-nav" type="button" 
                      aria-label="Toggle navigation" class="navbar-toggler" onClick={handleClickNav}><span class="navbar-toggler-icon"></span></Button>
            
                        <>
                    <Navbar className={`navbar-collapse collapse ${openNav ? 'show' : null}`} >

                      <Nav className="ml-auto ml-5 pr-5 navbar-menu">

                      </Nav>
                      <Nav className="topheader-dropdown">
                        <Box className=' auth-btns mr-4'>
                            <NavLink to="/event-calendar"><Button className="ml-2 btn signup-btn" variant="contained" color="secondary">
                              Event Calendar
                            </Button>
                          </NavLink>
                          {/* <NavLink to="/search-booking"><Button className="ml-2 btn signup-btn" variant="contained" color="secondary">
                              Manage Booking
                            </Button>
                          </NavLink> */}
                        </Box>
                        <Box className="socialicons">
                          <a href="https://www.facebook.com/ITWUserXperience" target="_blank"><SvgIcon><FacebookIcon /></SvgIcon></a>
                          <a href="https://www.instagram.com/itw_uxp/" target="_blank"><SvgIcon><InstagramIcon /></SvgIcon></a>
                          <a href="https://www.linkedin.com/company/itw-uxp/about/" target="_blank"><SvgIcon><LinkedInIcon /></SvgIcon></a>
                        </Box>
                        <Box className=' auth-btns'>
                          {/* <Button className="btn login-btn" variant="contained" color="primary" 
                          // onClick={handleClickOpen}
                          >
                            Customize Your Package
                          </Button> */}
                        
                          {/* <Button className="btn login-btn" variant="contained" color="primary" onClick={handleClickOpen}>
                            Login
                          </Button>
                          <Button className="ml-2 btn signup-btn" variant="contained" color="secondary" onClick={handleSignOpen}>
                            Sign Up
                          </Button> */}
                        </Box>
                      </Nav>
                      
                    </Navbar>
                  </>
                  </Box>
                </ClickAwayListener>  
              </Navbar>
          </Col>
        </Row>
      </Container>


      <Modal
        show={openmodal} onHide={handleClickClose} 
        aria-labelledby="form-dialog-title"
        className='login-dialog'
      >
        <div className='dailogbox'>
          <SignIn />
          <div className='slidersect'>

            <div className='slideitem'>
              <Slider {...lginslider}>
                <div className="imgslidebx">
                  <img src={img1} className="logoimg" alt="login-img" />
                </div>
                <div className="imgslidebx">
                  <img src={img2} className="logoimg" alt="login-img" />
                </div>
                <div className="imgslidebx">
                  <img src={img3} className="logoimg" alt="login-img" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Modal>

      {/* end login form */}

      {/* Signup popup */}
      <Modal
        show={opensignmodal} onHide={handleSignClose}
      
        aria-labelledby="form-dialog-title"
        className='dailogbox-container'
      >
        <div className='signup-modal'>
          <Row>
            <Col xs={12} md={5} className='reg-left-box'>
              <div className='slideitem'>
                <Slider {...lginslider}>
                  <div className="imgslidebx">
                    <img src={img1} className="logoimg" alt="login-img" />
                  </div>
                  <div className="imgslidebx">
                    <img src={img2} className="logoimg" alt="login-img" />
                  </div>
                  <div className="imgslidebx">
                    <img src={img3} className="logoimg" alt="login-img" />
                  </div>
                </Slider>
              </div>
            </Col>
            <Col xs={12} md={7}>
              <SignUp />
            </Col>
          </Row>
        </div>
      </Modal>

    </div>
  );
}

export default Header
